<template>
  <nuxt-link to="/" @click="onClickLogo">
    <div class="main-logo">
      <svg viewBox="0 0 178 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.9356 26.2581H29.5546V19.9471H0V26.2581H11.6245V47.1756H17.9356V26.2581ZM0 6.99418H29.5546V0.683105H0V6.99418Z"
          fill="#F89344"
        />
        <path
          d="M65.4202 40.8701H43.8357V26.9196H59.4453V20.6085H43.8357V6.99418H65.4202V0.683105H37.5247V47.1756H65.4202V40.8701Z"
          fill="#F89344"
        />
        <path
          d="M95.6418 13.9667C95.6418 17.9518 92.6543 20.9447 88.6693 20.9447H72.729V47.1756H79.04V27.2503H86.3433L95.9725 47.1756H102.95L92.6543 26.5888C92.6543 26.5888 101.953 23.6014 101.953 13.9667C101.953 6.66348 95.9725 0.683105 88.6693 0.683105H67.4155V6.99418H88.6693C92.6543 6.99418 95.6418 9.98162 95.6418 13.9667Z"
          fill="#F89344"
        />
        <path
          d="M133.833 13.9667C133.833 17.9518 130.841 20.9447 126.861 20.9447H111.582V47.1756H117.893V27.2503H126.861C134.164 27.2503 140.145 21.2754 140.145 13.9667C140.145 6.65796 134.164 0.683105 126.861 0.683105H106.269V6.99418H126.861C130.841 6.99418 133.833 9.98162 133.833 13.9667Z"
          fill="#F89344"
        />
        <path
          d="M147.448 32.5635C147.448 41.2006 154.09 47.8424 162.721 47.8424C171.353 47.8424 178 41.2006 178 32.5635V15.2948C178 6.66327 171.358 0.0214844 162.721 0.0214844C154.084 0.0214844 147.448 6.66327 147.448 15.2948V32.5635ZM171.689 32.5635C171.689 37.5462 167.704 41.5313 162.721 41.5313C157.738 41.5313 153.759 37.5462 153.759 32.5635V15.2948C153.759 10.3176 157.744 6.33256 162.721 6.33256C167.698 6.33256 171.689 10.3176 171.689 15.2948V32.5635Z"
          fill="#F89344"
        />
        <path
          d="M39.9004 64.3283H35.9319V66.3125H39.9004C41.8847 66.3125 43.2076 64.9897 43.2076 63.0054C43.2076 59.5881 37.6957 60.3597 37.6957 58.7061C37.6957 58.0447 38.2469 57.4936 38.9083 57.4936H42.5461V55.5093H38.9083C37.0343 55.5093 35.7114 56.8321 35.7114 58.7061C35.7114 62.1235 41.2233 61.3519 41.2233 63.0054C41.2233 63.7771 40.6721 64.3283 39.9004 64.3283Z"
          fill="#F89344"
        />
        <path
          d="M57.4502 59.3676C57.4502 56.9424 55.7966 55.2888 53.3714 55.2888C50.9462 55.2888 49.2926 56.9424 49.2926 59.3676V62.4542C49.2926 64.8795 50.9462 66.533 53.3714 66.533C55.7966 66.533 57.4502 64.8795 57.4502 62.4542V59.3676ZM51.2769 59.3676C51.2769 58.155 52.1588 57.2731 53.3714 57.2731C54.584 57.2731 55.4659 58.155 55.4659 59.3676V62.4542C55.4659 63.6668 54.584 64.5487 53.3714 64.5487C52.1588 64.5487 51.2769 63.6668 51.2769 62.4542V59.3676Z"
          fill="#F89344"
        />
        <path
          d="M63.0942 64.3283V66.3126H69.047V64.3283H67.0628V57.4936H69.2675V55.5094H67.0628V54.6275C67.0628 53.6353 67.8344 52.8637 68.8266 52.8637H69.9289V50.8794H68.8266C66.6218 50.8794 65.0785 52.4227 65.0785 54.6275V55.5094H63.0942V57.4936H65.0785V64.3283H63.0942Z"
          fill="#F89344"
        />
        <path
          d="M76.0141 62.5645C76.0141 64.7692 77.5574 66.3125 79.7621 66.3125H80.8645V64.3283H79.7621C78.77 64.3283 77.9983 63.5566 77.9983 62.5645V57.4935H80.2031V55.5093H77.9983V51.9817H76.0141V55.5093H74.0298V57.4935H76.0141V62.5645Z"
          fill="#F89344"
        />
        <path
          d="M93.3433 59.1471H91.5795L89.3748 63.1156V55.5093H85.8472V57.4936H87.3905V66.3125H89.5952L92.4614 61.1314L95.3276 66.3125H97.5323V55.5093H95.548V63.1156L93.3433 59.1471Z"
          fill="#F89344"
        />
        <path
          d="M107.366 66.3125H111.996V59.0369C111.996 56.9424 110.562 55.5093 108.468 55.5093H105.602V57.4936H108.468C109.35 57.4936 110.011 58.155 110.011 59.0369V59.6983H107.366C105.381 59.6983 104.058 61.0211 104.058 63.0054C104.058 64.9897 105.381 66.3125 107.366 66.3125ZM107.366 61.6826H110.011V64.3283H107.366C106.594 64.3283 106.043 63.7771 106.043 63.0054C106.043 62.2338 106.594 61.6826 107.366 61.6826Z"
          fill="#F89344"
        />
        <path
          d="M123.813 66.3125V64.3283H121.829V58.5959C121.829 58.5959 122.711 57.4936 124.033 57.4936H125.356V55.2888H124.474C122.711 55.2888 121.829 56.6117 121.829 56.6117V55.5093H118.301V57.4936H119.844V64.3283H117.86V66.3125H123.813Z"
          fill="#F89344"
        />
        <path
          d="M130.78 62.5645C130.78 64.7692 132.323 66.3125 134.528 66.3125H138.056V64.3283H134.528C133.536 64.3283 132.764 63.5566 132.764 62.5645V62.0133H138.717V59.2573C138.717 56.9424 137.063 55.2888 134.748 55.2888C132.433 55.2888 130.78 56.9424 130.78 59.2573V62.5645ZM134.748 57.2731C135.851 57.2731 136.733 58.155 136.733 59.2573V60.1392H132.764V59.2573C132.764 58.155 133.646 57.2731 134.748 57.2731Z"
          fill="#F89344"
        />
      </svg>
    </div>
  </nuxt-link>
</template>

<script setup lang="ts">
const onClickLogo = () => {
  window.scrollTo(0, 0);
};
</script>

<style scoped lang="scss">
.main-logo {
  width: 180px;
  height: 70px;
}

@media (max-width: 1200px) {
  .main-header {
    .main-logo {
      width: 100px;
      height: auto;
    }
  }
}

@media (max-width: 767px) {
  .main-logo {
    width: 135px;
    height: 52px;
  }
}
</style>
