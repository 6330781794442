<template>
  <main-header v-if="state.screenWidth > 950" />
  <main-header-mobile v-else />
  <main class="content">
    <slot />
  </main>
  <main-footer />
</template>

<script setup lang="ts">
import MainHeader from '~/components/layout/main-header.vue';
import MainFooter from '~/components/layout/main-footer.vue';
import mainHeaderMobile from '~/components/layout/main-header-mobile.vue';

const state = reactive({
  screenWidth: 767,
});
const getScreenWidth = () => {
  state.screenWidth = document.body.offsetWidth;
};
onMounted(() => {
  getScreenWidth();
  window.addEventListener('resize', getScreenWidth);
});
onUnmounted(() => {
  window.removeEventListener('resize', getScreenWidth);
});
</script>
