<template>
  <header class="main-header header-container dark-bg">
    <div class="container">
      <div class="main-header__wrapper">
        <main-logo />
        <div v-show="isMenuOpen" class="menu dark-bg">
          <nav class="main-nav">
            <nuxt-link :to="{ path: '/', hash: '#services' }" class="main-nav__link" @click="onToggleMenu"> Услуги </nuxt-link>
            <nuxt-link to="/projects" class="main-nav__link" @click="onToggleMenu"> Проекты </nuxt-link>
            <nuxt-link to="/manufacturing" class="main-nav__link" @click="onToggleMenu"> Производство </nuxt-link>
            <nuxt-link to="/erp" class="main-nav__link" @click="onToggleMenu">Логистика</nuxt-link>
            <nuxt-link to="/news" class="main-nav__link" @click="onToggleMenu"> Новости </nuxt-link>
            <nuxt-link to="/contacts" class="main-nav__link" @click="onToggleMenu"> Контакты </nuxt-link>
          </nav>
          <contacts />
        </div>
        <button v-if="!isMenuOpen" class="main-header__open-menu" aria-label="Открыть меню" @click="onToggleMenu">
          <burger />
        </button>
        <button v-else class="main-header__close-menu" aria-label="Закрыть меню" @click="onToggleMenu">
          <close-icon />
        </button>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import mainLogo from '~/components/icons/main-logo.vue';
import contacts from '~/components/blocks/contacts.vue';
import burger from '~/components/icons/burger.vue';
import closeIcon from '~/components/icons/close-icon.vue';

enum bodyOverflow {
  HIDDEN = 'hidden',
  AUTO = 'auto',
}

const isMenuOpen = ref<boolean>(false);

const onToggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
  document.body.style.overflow = isMenuOpen.value ? bodyOverflow.HIDDEN : bodyOverflow.AUTO;
};
</script>

<style scoped lang="scss">
.main-header {
  padding: 25px 0;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  height: 120px;
  max-width: 100%;
  box-sizing: border-box;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;

  &__open-menu,
  &__close-menu {
    background: transparent;
  }

  &__open-menu {
    display: none;
  }

  &__close-menu {
    position: absolute;
    top: 40px;
    right: 18px;
    width: 40px;
    height: 40px;
    font-size: 0;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .menu {
    position: fixed;
    width: 100vw;
    height: calc(100vh - 120px);
    top: 120px;
    left: 0;
    z-index: 100;
    padding: 35px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
}

.header-container {
  display: flex;
  align-items: center;
}

.main-nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  margin-bottom: 140px;
  &__link {
    font-size: var(--header-menu-text);
    line-height: var(--header-menu-line);
    color: var(--white);
  }
}

@media (max-width: 950px) {
  .main-header {
    &__open-menu {
      display: block;
    }
  }
}
</style>
